<template>
    <nav>
      <div class="pagination">
        <button :disabled="currentPage == 1" @click="controls(-1)">
          Anterior
        </button>
        <button
          v-for="(page, index) in pagesToShow"
          :key="index"
          @click="changePage(page)"
          :class="{ active: page === value }"
        >
          {{ page }}
        </button>
        <button :disabled="(currentPage === totalPages) || (totalPages == 0)" @click="controls(1)">
          Siguiente
        </button>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: Number,
        required: true,
      },
      totalPages: {
        type: Number,
        required: true,
      },
      totalVisible: {
        type: Number,
        default: 3,
      },
    },
    emits: ["update:modelValue"],
    data: () => {
      return {
      };
    },
    computed: {
      currentPage: {
        get() {
          return this.value;
        },
        set(newPage) {
          this.$emit("input", newPage);
        },
      },
      pagesToShow() {
        const currentPage = this.value;
        const total = this.totalPages;
        const totalVisible = this.totalVisible;
        const halfVisible = Math.floor(totalVisible / 2);
        let from = currentPage - halfVisible;
        let to = currentPage + halfVisible;
  
        // Asegurar que las páginas mostradas estén dentro del rango válido
        if (from < 1) {
          from = 1;
          to = Math.min(totalVisible, total);
        }
        if (to > total) {
          to = total;
          from = Math.max(1, total - totalVisible + 1);
        }
  
        // Crear un array con las páginas que se mostrarán
  
        const pages = [];
        if (from > 1) {
          pages.push(1);
          if (from > 2) {
            pages.push("..."); // Agregar puntos suspensivos al principio o al final
          }
        }
        for (let i = from; i <= to; i++) {
          pages.push(i);
        }
        if (to < total) {
          if (to < total - 1) {
            pages.push("..."); // Agregar puntos suspensivos  al final
          }
          pages.push(total);
        }
        return pages;
      },
    },
    watch: {
      value(newValue, oldValue) {
        this.$emit("change", newValue, oldValue);
      },
    },
    methods: {
      changePage(page) {
        if (page >= 1 && page <= this.totalPages && page !== this.currentPage) {
          this.currentPage = page;
          this.$emit("page-changed", page);
        }
      },
      controls(variable) {
        this.currentPage = this.currentPage + variable;
        this.$emit("page-changed", this.currentPage);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos opcionales para dar formato a la paginación */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .pagination button {
    color: #1C1E4D;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 3px 7px;
    margin: 0 5px;
  }
  
  .pagination button:hover {
    background-color: #1C1E4D;
    border-radius: 5px;
    color: #fff;
  }
  
  .pagination button.active {
    background-color: #1C1E4D;
    border-radius: 5px;
    color: #fff;
    cursor: default;
  }
  
  .pagination button:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  </style>