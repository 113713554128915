import Vue from "vue";
import auth from "./auth.services";
import users from "./user.services";
import roles from "./roles.services";
import profiles from "./profiles.services";
import typeRoles from "./typeRoles.services";
import paths from "./path.services";
import catalogosGenerales from "./catalogosGenerales";
import catalogosPlaza from "./catalogosPlaza";
import planillaServices from "./planilla.services";
import empleadoPlanilla from "./empleadoPlanilla";
import configuracionesParametrosSistema from "./configuracionesParametrosSistema.services";
import especificoGasto from "./especificoGasto.services";
import estructura from "./estructura.services";
import puestoUnidadService from "./puestoUnidad.service";
import cifradoPresupuestario from "./cifradoPresupuestario.service";
import categoriaPuestoService from "./categoriaPuesto.service";
import organigramaServices from "./organigrama.services";
import movimientos from "@/views/acciones-movimientos/services/index.js";
import peticiones from "./peticiones.services";
import catalogosPermutas from "./catalogosPermutas.services";
import renunciasServices from "./renuncias.services";
import aumento from "./aumento.services";
import vialidad from "../views/configuracionParametrosSistema/services/vialidad";
import categoriaDescuento from "./categoriaDescuento.services";
import gestionPlanillas from "./gestionPlanillas.services";
import planillaDescuentos from "./planillaDescuentos.service";
import nominaV2Services from "./nominaV2.services";
import plazasServices from "./plazas.services";
import reportes from "./reportes.services";
import reportesGenerales from "../views/reportes/services/reportesGenerales";
import historicoRenta from "./historicoRenta.services";
import rolesEmpleadosServices from "@/views/gestionRoles/rolesEmpleados/services/rolesEmpleados";
import gestionRol from "../views/gestionRoles/roles/services/gestionRol.services";
import resumenPlanillas from "./resumenPlanillas.services-";
import salarioMinimo from "./salarioMinimo.services";
import refrenda from "./refrenda.services";
import plantillasAmonestacionServices from "./plantillasAmonestacion.services";
import amonestacion from "../views/acciones-movimientos/amonestacion/services/amonestaciones";
import licencias from "../views/acciones-movimientos/licencia/services/licencias";

Vue.prototype.services = {
  auth,
  users,
  roles,
  profiles,
  typeRoles,
  paths,
  catalogosGenerales,
  catalogosPlaza,
  planillaServices,
  empleadoPlanilla,
  configuracionesParametrosSistema,
  especificoGasto,
  estructura,
  puestoUnidadService,
  cifradoPresupuestario,
  categoriaPuestoService,
  organigramaServices,
  movimientos,
  peticiones,
  catalogosPermutas,
  renunciasServices,
  aumento,
  vialidad,
  categoriaDescuento,
  gestionPlanillas,
  planillaDescuentos,
  nominaV2Services,
  plazasServices,
  reportes,
  reportesGenerales,
  historicoRenta,
  rolesEmpleadosServices,
  gestionRol,
  resumenPlanillas,
  salarioMinimo,
  refrenda,
  plantillasAmonestacionServices,
  amonestacion,
  licencias,
};
