<template>
  
  <v-navigation-drawer
    :value="sideBar"
    fixed
    app
    @input="setSideBar($event)"
    style="box-shadow: 2px 2px 15px #cecece; height: 100% !important;"
    :style="!$vuetify.breakpoint.smAndDown ? 'border-radius: 0px 15px 15px 0px' : ''"
    color="#ffffff"
    :width="widthSidebar"
    disable-resize-watcher
  >
    <template v-slot:prepend>
      <!--      close menu-->
      <v-btn
        icon
        @click="setSideBar(false)"
        v-if="$vuetify.breakpoint.smAndDown"
        class="sidebar--close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-list-item two-line class="flex justify-center" style="width: 100%">
        <v-img class="mt-15 mb-15 sidebar--logo" src="@/assets/img/Capa_1.svg"></v-img>
      </v-list-item>
      
      
      <v-text-field
        dense
        rounded
        filled
        placeholder="Buscar en el menú"
        prepend-inner-icon="mdi-magnify"
        class="mx-5"
        v-model="search"
        @input="searchMenuOptions"
        clearable
      />
    </template>
    
    <v-list nav dense>
      <span v-for="(item, i) in menuLocal" :key="i">
        <!-- Tiene hijos -->
        <v-list-group
          :value="false"
          active-class="selectedBgPurple--text"
          v-if="item.dependencias && item.dependencias.length"
        >
          <template #prependIcon>
            <v-icon color="#313945">{{ item.icono }}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-title
              style="
                color: #313945;
                font-size: 0.8125rem;
                font-weight: 500 !important;
                line-height: 1rem;
              "
            >{{ item.titulo }}</v-list-item-title
            >
          </template>

          <span v-for="(child, j) in item.dependencias" :key="j">
            <v-list-group
              :value="false"
              no-action
              sub-group
              active-class="selectedBgPurple--text"
              v-if="child.dependencias && child.dependencias.length"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    style="
                      color: #313945;
                      font-size: 0.8125rem;
                      font-weight: 500 !important;
                    "
                  >{{ child.titulo }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(sub_child, k) in child.dependencias"
                :key="k"
                @click="redirect(item.modulo.base_url, `${item.modulo.base_url + sub_child.ruta}`)"
              >
                <v-list-item-title
                  style="
                    color: #313945;
                    font-size: 0.8125rem;
                    font-weight: 500 !important;
                  "
                >{{ sub_child.titulo }}</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon color="#313945">{{ sub_child.icono }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              active-class=" selectedBgPurple--text"
              @click="redirect(item.modulo.base_url, `${item.modulo.base_url + child.ruta}`)"
            >
              <v-list-item-icon>
                <v-icon color="#313945">{{ child.icono }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title
                style="
                  color: #313945;
                  font-size: 0.8125rem;
                  font-weight: 500 !important;
                "
              >{{ child.titulo }}</v-list-item-title
              >
            </v-list-item>
          </span>
        </v-list-group>
        <!-- No tiene hijos -->
        <v-list-item
          v-else
          @click="redirect(item.modulo.base_url, `${item.modulo.base_url + item.ruta}`)"
          active-class=" selectedBgPurple--text"
        >
          <v-list-item-icon>
            <v-icon color="#313945">{{ item.icono }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title
            style="color: #313945; font-size: .8125rem; font-weight: 500 !important"
          >{{ item.titulo }}</v-list-item-title
          >
        </v-list-item>
      </span>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import jwtDecode from "jwt-decode";

export default {
  name: "AppAsideBar",
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      search: "",
      menuLocal: [],
      screenWidth: window.innerWidth,
    };
  },
  
  watch: {
    menu: {
      handler: function (val) {
        this.menuLocal = val;
        const menu_principal = {
        titulo: "MENÚ PRINCIPAL",
        icono: "mdi-home",
        modulo: { base_url: localStorage.getItem("urlPrincipal") || "https://rrhh.innovacion.gob.sv" },
        ruta: "",
      };

      if (!this.menuLocal.some(item => item.titulo === menu_principal.titulo)) {
        this.menuLocal.unshift(menu_principal);
      }
      },
      immediate: true,
    },
    
    screenWidth: {
      handler: function () {
        this.setSideBar(false);
      },
    },
  },
  
  updated() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    redirect(base_url, url) {
      const token = localStorage.getItem("token");
      const sessionUuid = localStorage.getItem("session_uuid");

      if(base_url == window.location.origin) {
        window.location = url;
      } else {
        window.location = `${url}?session=${sessionUuid}`;
      }
    },
    
    searchMenuOptions() {
      let menusFound = [];
      
      if (!this.search) {
        this.menuLocal = this.menu;
        return;
      }
      
      const searchRecursive = (items, base_url = null) => {
        items.forEach((item) => {
          if (item.titulo.toLowerCase().includes(this.search.toLowerCase())) {
            if (base_url) {
              item.modulo = {base_url};
            };
            menusFound.push(item);
          }
          
          // Continúa la búsqueda en las dependencias del elemento actual
          if (item.dependencias) {
            searchRecursive(item.dependencias, item?.modulo?.base_url);
          }
        });
      };
      
      //inicia busqueda en menu principal
      searchRecursive(this.menu);
      this.menuLocal = menusFound;
    },
    
    
  },
  computed: {
    ...mapState("utils", ["sideBar"]),
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return !this.sideBar;
      } else if (this.$vuetify.breakpoint.xs) {
        return !this.sideBar;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      } else {
        return !this.sideBar;
      }
    },
    
    widthSidebar() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return '100%';
      } else if (this.$vuetify.breakpoint.sm) {
        return '75%';
      } else if (this.$vuetify.breakpoint.md) {
        return '65%';
      } else if (this.$vuetify.breakpoint.lg) {
        return '35%';
      } else if (this.$vuetify.breakpoint.xl) {
        return '30%';
      } else {
        return '70%';
      }
    }
  },
  
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style scoped>
:deep(.v-list-group__items) {
  flex: 1 1 auto;
  /* background-color: #f5f9ff; */
  background-color: #ffff;
  border-radius: 10px;
}

:deep(.mdi-chevron-down::before) {
  content: "\F0140";
  color: #313945;
}

.sidebar--logo {
  width: 100%;
  height: calc(100% - 20px);
  max-width: 250px;
  margin: 0 auto;
}

.sidebar--close {
  float: right;
  right: 10px;
  top: 15px;
}
</style>