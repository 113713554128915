import { http_client } from "@/plugins/http_client";

const obtenerPagadurias = async (params = {}) =>
  await http_client(`/api/v2/nomina/catalogo/pagadurias`, params, "get");
const obtenerBancos = async (params = {}) =>
  await http_client(`/api/v2/nomina/catalogo/bancos`, params, "get");
const obtenerCuentasBancarias = async (idEmpleado, page, params = {}) =>
  await http_client(
    `/api/v2/nomina/cuenta-banco/empleado/${idEmpleado}?page=${page}&per_page=10`,
    params,
    "get"
  );
const guardarCuentaBancaria = async (body) =>
  await http_client(`/api/v2/nomina/cuenta-banco/empleado`, body, "post");
const actualizarCuentaBancaria = async (idCuentaBanco, body) =>
  await http_client(
    `/api/v2/nomina/cuenta-banco/empleado/${idCuentaBanco}`,
    body,
    "post"
  );
const activarDesactivarCuentaBancaria = async (idCuentaBanco) =>
  await http_client(
    `/api/v2/nomina/cuenta-banco/empleado/${idCuentaBanco}`,
    {},
    "DELETE"
  );
const obtenerDocumentoCuenta = async (idCuentaBanco) =>
  await http_client(
    `/api/v2/nomina/cuenta-banco/empleado/doc/${idCuentaBanco}`,
    {},
    "get"
  );

const obtenerTiposDescuentos = async (params = {}) =>
  await http_client(
    `/api/v2/nomina/catalogo/categoria-descuentos`,
    params,
    "get"
  );

const obtenerAcreedores = async (params = {}) =>
  await http_client(`/api/v2/nomina/catalogo/acreedores`, params, "get");

const obtenerIngresosPlanilla = async (params = {}) =>
  await http_client(`/api/v2/nomina/catalogo/ingresos-planilla`, params, "get");

const guardarOrdenDescuento = async (body) =>
  await http_client(`/api/v2/nomina/orden-descuento/empleado`, body, "post");

const editarOrdenDescuento = async (idOrdenDescuento, body) =>
  await http_client(
    `/api/v2/nomina/orden-descuento/empleado/${idOrdenDescuento}`,
    body,
    "post"
  );

const obtenerOrdenesDescuento = async (idEmpleado, page, params = {}) =>
  await http_client(
    `/api/v2/nomina/orden-descuento/empleado/${idEmpleado}?page=${page}&per_page=10`,
    params,
    "get"
  );

const obtenerDocumentoOrdenDescuento = async (idOrden) =>
  await http_client(
    `/api/v2/nomina/orden-descuento/empleado/doc/${idOrden}`,
    {},
    "get"
  );

const cancelarOrdenDescuento = async (idOrden, body) =>
  await http_client(
    `/api/v2/nomina/orden-descuento/empleado/cancelar/${idOrden}`,
    body,
    "post"
  );

const obtenerEstadoPuestoEmpleado = async () => await http_client("api/");

export default {
  obtenerPagadurias,
  obtenerBancos,
  obtenerCuentasBancarias,
  guardarCuentaBancaria,
  actualizarCuentaBancaria,
  activarDesactivarCuentaBancaria,
  obtenerDocumentoCuenta,
  obtenerTiposDescuentos,
  obtenerAcreedores,
  obtenerIngresosPlanilla,
  guardarOrdenDescuento,
  editarOrdenDescuento,
  obtenerOrdenesDescuento,
  obtenerDocumentoOrdenDescuento,
  cancelarOrdenDescuento,
  obtenerEstadoPuestoEmpleado
};
