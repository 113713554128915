export default [
  {
    path: 'acciones-movimientos/licencia',
    name: 'licencias',
    component: () => import(/* webpackChunkName: "ascenso" */ './index.vue'),
  },
  {
    path: 'acciones-movimientos/licencia/solicitud/:id',
    name: 'solicitud-licencia',
    component: () => import(/* webpackChunkName: "ascenso" */ './views/solicitudView.vue'),
  }
]