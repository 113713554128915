import { http_client } from "@/plugins/http_client";

function getParams(params) {
  return params ? new URLSearchParams(Object.entries(params)).toString() : "";
}
const primerPaso = {
    estructura: () => http_client(`/api/primer-paso/estructura`, {}, "get"),
    cargarArchivo: (archivo) =>
        http_client("/api/importar/primer-paso", archivo, "post", {
            headers: {
                "content-type": "multipart/form-data"
            },
        }),
    registrar: (body) =>
        http_client("/api/primer-paso/registrar", body, "post"),
    registrarDependencia: (body) =>
        http_client("/api/primer-paso/insertar-hijo", body, "post"),
    actualizarUnidad: (body, id) =>
        http_client(`/api/primer-paso/editar-unidad-organizativa/${id}`, body, "put"),
    registrarJefatura: (body) =>
        http_client("/api/primer-paso/insertar-jefatura", body, "post"),
    eliminarJefatura: (id) =>
        http_client(`/api/primer-paso/eliminar-jefatura/${id}`, {}, "delete"),
    obtenerJefaturas: (params) =>
        http_client(`/api/primer-paso/consultar-jefatura`, params, "get"),
    plantilla: (body) =>
        http_client(
            "/api/importar/primer-paso/descargar",
            body,
            "post", {
                "content-type": "blob",
            },
            true,
            "arraybuffer"
        ),
    eliminarUnidad: (id) =>
        http_client(`/api/primer-paso/eliminar-unidad-organizativa/${id}`, {}, "delete"),
    historialCambios: (id, data) =>
        http_client(`/api/revision-historicos-unidad/${id}`, data, "get"),
};

const segundoPaso = {
  listar: ({ params, data }) =>
    http_client(`/api/mnt_cifrado/search?` + getParams(params), data, "post"),
  cargarArchivo: (archivo) =>
    http_client("/api/importar/v1/segundo-paso", archivo, "post", {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  //
  listarFF: () =>
    http_client(`/api/fuentes-financiamiento`, {}, "get"),
  eliminar: (body) => http_client(`/api/mnt_cifrado/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/cifrado-carga-inicial`, body, "post"),
  editar: (body) => http_client(`/api/cifrado-carga-inicial`, body, "post"),
  actualizar: (body) => http_client(`/api/mnt_cifrado/${body.id}`, body, "put"),
  plantilla: (body) =>
    http_client(
      "/api/importar/cifrado-presupuestario/descargar",
      body,
      "post",
      {
        "content-type": "blob",
      },
      true,
      "arraybuffer"
    ),
};

const tercerPaso = {
  listar: ({ params, data }) =>
    http_client(
      `/api/establecimiento_especifico_gasto/search?` + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(
      `/api/establecimiento_especifico_gasto/${body.id}`,
      body,
      "put"
    ),
  estado: () => http_client(`/api/tercer-paso/estado`, {}, "get"),
  restablecer: () => http_client(`/api/tercer-paso/reestablecer`, {}, "post"),
};

const cuartoPaso = {
  listar: ({ params, data }) =>
    http_client(
      `/api/mnt_detalle_plaza/search?` + getParams(params),
      data,
      "post"
    ),
  cargarArchivo: (archivo) =>
    http_client("/api/importar/cuarto-paso", archivo, "post", {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  crear: (body) => http_client(`/api/cuarto-paso`, body, "post"),
  actualizar: (body) =>
    http_client(`/api/cuarto-paso/editar/${body.id}`, body, "put"),
  plantilla: (body) =>
    http_client(
      "/api/importar/cuarto-paso/descargar",
      body,
      "post",
      {
        "content-type": "blob",
      },
      true,
      "arraybuffer"
    ),
};

const quintoPaso = {
    listar: ({
            params,
            data
        }) =>
        http_client(
            `/api/mnt_puesto_funcional/listar-cargos-funcionales?` + getParams(params),
            data,
            "get"
        ),
    cargarArchivo: (archivo) =>
        http_client("/api/importar/quinto-paso", archivo, "post", {
            headers: {
                "content-type": "multipart/form-data"
            },
        }),
    eliminar: (body) =>
        http_client(`/api/mnt_puesto_funcional/eliminar-cargo-funcional/${body.id}`, {}, "delete"),
    crear: (body) => http_client(`/api/mnt_puesto_funcional/crear-cargo-funcional`, body, "post"),
    actualizar: (body) =>
        http_client(`/api/mnt_puesto_funcional/editar-cargo-funcional/${body.id}`, body, "put"),
    cambiarEstado: (body) =>
        http_client(`/api/mnt_puesto_funcional/cambiar-estado-puesto-funcional/${body.id}`, {}, "post"),
    historialCambios: (id) =>
      http_client(`/api/historico-cargo-funcional/${id}`, {}, "get"),
    plantilla: (body) =>
        http_client(
            "/api/importar/quinto-paso/descargar",
            body,
            "post", {
                "content-type": "blob",
            },
            true,
            "arraybuffer"
        ),
};

const sextoPaso = {
    listar: ({
            params,
            data
        }) =>
        http_client(`/api/sexto-paso?` + getParams(params), data, "get"),
    cargarArchivo: (archivo) =>
        http_client("/api/importar/v2/sexto-paso", archivo, "post", {
            headers: {
                "content-type": "multipart/form-data"
            },
        }),
    plantilla: (body) =>
        http_client(
            "/api/importar/sexto-paso/descargar",
            body,
            "post", {
                "content-type": "blob",
            },
            true,
            "arraybuffer"
        ),
    detalle: (id, id_empleado_puesto) => http_client(`/api/importar/informacion-empleado/${id}/${id_empleado_puesto}`, {}, "get"),
    eliminarEmpleadoPuesto: (id) =>
        http_client(`/api/sexto-paso/empleado/${id}`, {}, "delete"),
    // descagar unidad
    descargarUnidad:(body) =>
    http_client(
      "/api/v1/exportar/unidad-organizativa",
      body,
      "get",
      {
        "content-type": "blob",
      },
      true,
      "arraybuffer"
    ),
  // descargar cargo
  descargarCargo: (body) =>
    http_client(
      "/api/v1/exportar/cargo-funcional",
      body,
      "get",
      {
        "content-type": "blob",
      },
      true,
      "arraybuffer"
    ),
  // descargar pLaza
  descargarPlaza: (body) =>
    http_client(
      "/api/v1/exportar/plaza-establecimiento",
      body,
      "get",
      {
        "content-type": "blob",
      },
      true,
      "arraybuffer"
    ),
  //carga inicial finalizada
  cargaInicialFinalizada: (body) =>
    http_client("/api/sexto-paso/carga-finalizada", body, "post"),


    consultarCargaFinalizada: () =>
        http_client("/api/cargas-iniciales/estado-carga-inicial", {}, "get"),


    listarErroresCarga: () =>
        http_client(`/api/cargas-iniciales/historial-empleados-no-cargados`, {}, "get"),

    descargarErrores: (body) =>
        http_client(
            "/api/cargas-iniciales/descargar-empleados-no-cargados",
            body,
            "get", {
                "content-type": "blob",
            },
            true,
            "arraybuffer"
        ),
};

const exportarCatalogos = {
  exportarCatalogo: (body) =>
    http_client("/api/importar/catalogos", body, "post"),
};

const pasoActual = (data) => http_client(`/api/paso-actual`, data, "get");
const historialPrincipalCargaEmpleados = (data) =>
  http_client(`api/cargas-iniciales/historial-empleados-cargados`, data, "get");
const historialCargaEmpleadosCargados = (id, page = 1, busqueda = "") => {
  const queryParams = new URLSearchParams();
  queryParams.append("page", page);

  if (busqueda) {
    queryParams.append("busqueda", busqueda);
  }

  return http_client(
    `/api/cargas-iniciales/detalle-historial-carga-inicial/${id}?${queryParams.toString()}`
  );
};

const historialCargaEmpleadosCargadosInformacion = (id) =>
  http_client(`/api/cargas-iniciales/informacion-empleado-cargado/${id}`);

const historialEmpleadosPostCarga = (institucion = "" , establecimiento = "" , fecha_desde = "" , fecha_hasta = "", nombre = "" ) => {
  const queryParams = new URLSearchParams();

  if (institucion) {
    queryParams.append("institucion", institucion);
  }

  if (establecimiento) {
    queryParams.append("establecimiento", establecimiento);
  }

  if (fecha_desde) {

    queryParams.append("fecha_desde", fecha_desde);
  }

  if (fecha_hasta) {

    queryParams.append("fecha_hasta", fecha_hasta);
  }

  if (nombre) {
    queryParams.append("nombre", nombre);
  }

  return http_client(
    `/api/nomina/historial-empleados?${queryParams.toString()}`
  );
};

const detalleEmpleadoPostCarga = (id) =>
  http_client(`/api/nomina/historial-empleados/${id}`);

const descargarDocumentos = async (id) => await http_client(`/api/nomina/documento-historial-empleado/${id}`)

export default {
  primerPaso,
  segundoPaso,
  tercerPaso,
  cuartoPaso,
  quintoPaso,
  sextoPaso,
  pasoActual,
  exportarCatalogos,
  historialPrincipalCargaEmpleados,
  historialCargaEmpleadosCargados,
  historialCargaEmpleadosCargadosInformacion,
  historialEmpleadosPostCarga,
  detalleEmpleadoPostCarga,
  descargarDocumentos
};

