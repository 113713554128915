export default [
    {
        path: "/estructura",
        name: "estructura",
        component: () =>
            import(/* webpackChunkName: "estructuraStepper" */ "./index.vue"),
    },
    {
        path: "/estructura/expediente-empleado/:idPersona",
        name: "estructura-expediente",
        component: () =>
            import(
                /* webpackChunkName: "nomina" */ "../nomina/components/expedienteEmpleado.vue"
            ),
    },
    {
        path: "/estructura/historial-empleado",
        name: "estructura-historial-empleado",
        component: () => import(/* webpackChunkName: "estructura" */ "./steps/HistoricoCargaEmpleado.vue"),
    }
];
