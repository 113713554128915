import { http_client } from "../../../../plugins/http_client";

const obtenerListadoLicencia = async ( params = {} ) =>
    await http_client("/api/licencia/listado", params, "get");

//CATALOGOS
const obtenerListadoEstados = async ( params = {}) =>
    await http_client('api/licencia/estados', params);

const obtenerListadoTipoLicencia = async ( params = {}) =>
    await http_client('api/licencia/tipo-licencia', params);

//Solicitud

const obtenerDetalleSolicitud = async ( id ) =>
    await http_client(`/api/licencia/solicitud/${id}`, {}, "get");

const verDocumento = async ( id ) => {
    await http_client(`/api/v1/portal/documentos-solicitud-licencia/${id}`, {}, "get");
}

const enviarSolicitud = async ( params = {} ) => {
    await http_client("/api/licencia/cambiar-fase-solicitud", params, "post");
}

const denegarSolicitud = async ( params = {} ) => {
    await http_client("/api/licencia/denegar-solicitud", params, "post");
}

export default {
    obtenerListadoLicencia,
    obtenerListadoEstados,
    obtenerListadoTipoLicencia,
    obtenerDetalleSolicitud,
    verDocumento,
    enviarSolicitud,
    denegarSolicitud
}