export default [
  {
    path: "/pais",
    name: "Pais",
    component: () =>
      import(/* webpackChunkName: "pais" */ "./generales/Pais.vue"),
  },
  {
    path: "/institucion",
    name: "Institucion",
    component: () =>
      import(
        /* webpackChunkName: "institucion" */ "./generales/institucion/index.vue"
      ),
  },
  {
    path: "/discapacidad",
    name: "Discapacidad",
    component: () =>
      import(
        /* webpackChunkName: "discapacidad" */ "./generales/Discapacidad.vue"
      ),
  },
  {
    path: "/tipo-establecimiento",
    name: "TipoEstablecimiento",
    component: () =>
      import(
        /* webpackChunkName: "tipoEstablecimiento" */ "./generales/TipoEstablecimiento.vue"
      ),
  },
  {
    path: "/establecimiento",
    name: "Establecimiento",
    component: () =>
      import(
        /* webpackChunkName: "establecimiento" */ "./generales/Establecimiento.vue"
      ),
  },
  {
    path: "/departamento",
    name: "Departamento",
    component: () =>
      import(/* webpackChunkName: "pais" */ "./generales/Departamento.vue"),
  },
  {
    path: "/estado-civil",
    name: "EstadoCivil",
    component: () =>
      import(
        /* webpackChunkName: "estadoCivil" */ "./generales/EstadoCivil.vue"
      ),
  },
  {
    path: "/formacion-academica",
    name: "FormacionAcademica",
    component: () =>
      import(
        /* webpackChunkName: "formacionAcademica" */ "./generales/FormacionAcademica.vue"
      ),
  },
  {
    path: "/municipio",
    name: "Municipio",
    component: () =>
      import(/* webpackChunkName: "municipio" */ "./generales/Municipio.vue"),
  },
  {
    path: "/nivel-educativo",
    name: "NivelEducativo",
    component: () =>
      import(
        /* webpackChunkName: "nivelEducativo" */ "./generales/NivelEducativo.vue"
      ),
  },
  {
    path: "/parentesco",
    name: "Parentesco",
    component: () =>
      import(/* webpackChunkName: "parentesco" */ "./generales/Parentesco.vue"),
  },
  {
    path: "/administradora-pensiones",
    name: "AdministradoraPensiones",
    component: () =>
      import(/* webpackChunkName: "parentesco" */ "./generales/AdministradoraPensiones.vue"),
  },
  {
    path: "/tipo-documento",
    name: "TipoDocumento",
    component: () =>
      import(
        /* webpackChunkName: "tipoDocumento" */ "./generales/TipoDocumento.vue"
      ),
  },
  {
    path: "/tipo-institucion",
    name: "TipoInstitucion",
    component: () =>
      import(
        /* webpackChunkName: "tipoInstitucion" */ "./generales/TipoInstitucion.vue"
      ),
  },
  {
    path: "/tipo-sangre",
    name: "TipoSangre",
    component: () =>
      import(/* webpackChunkName: "tipoSangre" */ "./generales/TipoSangre.vue"),
  },
  // rutas nuevas
  {
    path: "/institucion-financiera",
    name: "InstitucionFinanciera",
    component: () =>
      import(
        /* webpackChunkName: "tipoSangre" */ "./generales/InstitucionesFinancieras.vue"
      ),
  },
  {
    path: "/pagaduria",
    name: "Pagaduria",
    component: () =>
      import(/* webpackChunkName: "tipoSangre" */ "./generales/Pagaduria.vue"),
  },
  {
    path: "/tipo-acreedor",
    name: "TipoAcreedor",
    component: () =>
      import(
        /* webpackChunkName: "tipoSangre" */ "./generales/TipoAcreedores.vue"
      ),
  },
  {
    path: "/acreedor",
    name: "Acreedor",
    component: () =>
      import(/* webpackChunkName: "tipoSangre" */ "./generales/Acreedores.vue"),
  },
  {
    path: "/presupuesto-financia",
    name: "PresupuestoFinancia",
    component: () =>
      import(
        /* webpackChunkName: "presupuesto" */ "./catalogosPlaza/PresupuestoFinancia.vue"
      ),
  },
  {
    path: "/estado-plaza",
    name: "EstadoPlaza",
    component: () => import("./catalogosPlaza/EstadoPlazas.vue"),
  },
  {
    path: "/plaza-nominal",
    name: "PlazaNominal",
    component: () => import("./catalogosPlaza/PlazaNominal.vue"),
  },
  {
    path: "/configurador-institucion",
    name: "ConfiguradoresInstitucion",
    component: () => import("./generales/ConfiguradoresInstitucion.vue"),
  },
  {
    path: "/puesto-funcional",
    name: "PuestoFuncional",
    component: () => import("./generales/PuestoFuncional.vue"),
  },
  {
    path: "/especifico-gasto",
    name: "EspecificoGasto",
    component: () => import("./especificoGasto/EspecificoGasto.vue"),
  },
  {
    path: "/cifrado-presupuestario",
    name: "CifradoPresupuestario",
    component: () =>
      import("./cifradoPresupuestario/CifradoPresupuestario.vue"),
  },
  {
    path: '/categoria-puesto',
    name: 'CategoriaPuesto',
    component: () => import("./categoriaPuesto/CategoriaPuesto.vue")
  },
  {
    path: '/categoria-descuento',
    name: 'CategoriaDescuentos',
    component: () => import("./categoriaDescuentos/categoriaDescuento.vue")
  },
  {
    path: "/unidad-presupuestaria",
    name: "UnidadPresupuestaria",
    component: () =>
      import(
        /* webpackChunkName: "presupuesto" */ "./unidadPresupuestaria/UnidadPresupuestaria.vue"
      ),
  },
];
