import { http_client } from "@/plugins/http_client";

function getParams(params) {
  return params ? new URLSearchParams(Object.entries(params)).toString() : "";
}

//ctl_sistema-pago

const SistemaPago = {
    listar: ({ params, data }) => http_client(`/api/ctl_sistema_pago/search?` + getParams(params), data, "post"),
    actualizar: (body) => http_client(`/api/ctl_sistema_pago/${body.id}`, body, "patch"),
    desactivar: (body) => http_client(`/api/ctl_sistema_pago/${body.id}`, {},"delete"),
    activar: (body) => http_client(`/api/ctl_sistema_pago/${body.id}/restore`, {}, "post"),
    crear: (body) => http_client(`/api/ctl_sistema_pago`, body, "post"),
    catalogo: () => http_client(`/api/sistema-pago/catalogo`, {}, "get"),
  };

const PresupuestoFinancia = {
    listar: ({ params, data }) => http_client(`/api/ctl_presupuesto_financia/search?` + getParams(params), data, "post"),
    actualizar: (body) => http_client(`/api/ctl_presupuesto_financia/${body.id}`, body, "patch"),
    desactivar: (body) => http_client(`/api/ctl_presupuesto_financia/${body.id}`, {},"delete"),
    activar: (body) => http_client(`/api/ctl_presupuesto_financia/${body.id}/restore`, {}, "post"),
    crear: (body) => http_client(`/api/ctl_presupuesto_financia`, body, "post"),
}

const UnidadPresupuestaria = {
    listar: ({ params, data }) => http_client(`/api/mnt_unidad_presupuestaria/search?` + getParams(params), data, "post"),
    actualizar: (body) => http_client(`/api/mnt_unidad_presupuestaria/${body.id}`, body, "patch"),
    desactivar: (body) => http_client(`/api/mnt_unidad_presupuestaria/${body.id}`, {},"delete"),
    activar: (body) => http_client(`/api/mnt_unidad_presupuestaria/${body.id}/restore`, {}, "post"),
    crear: (body) => http_client(`/api/mnt_unidad_presupuestaria`, body, "post"),
}

const TipoFinanciamiento = {
    listar: ({ params, data }) => http_client(`/api/ctl_tipo_financiamiento/search?` + getParams(params), data, "post"),
    actualizar: (body) => http_client(`/api/ctl_tipo_financiamiento/${body.id}`, body, "patch"),
    desactivar: (body) => http_client(`/api/ctl_tipo_financiamiento/${body.id}`, {},"delete"),
    activar: (body) => http_client(`/api/ctl_tipo_financiamiento/${body.id}/restore`, {}, "post"),
    crear: (body) => http_client(`/api/ctl_tipo_financiamiento`, body, "post"),
}

const Formalizacion = {
    listar: ({ params, data }) => http_client(`/api/ctl_formalizacion/search?` + getParams(params), data, "post"),
    actualizar: (body) => http_client(`/api/ctl_formalizacion/${body.id}`, body, "patch"),
    desactivar: (body) => http_client(`/api/ctl_formalizacion/${body.id}`, {},"delete"),
    activar: (body) => http_client(`/api/ctl_formalizacion/${body.id}/restore`, {},'post'),
    crear: (body) => http_client(`/api/ctl_formalizacion`, body, "post"),
}
const CategoriaPlazas = {
  listar: ({params, data}) => http_client("/api/ctl_categoria_plaza/search?" + getParams(params), data, "post"),
  listaOrdenada: (body) => http_client("/api/ctl_categoria_plaza/search", body, "post"),
  actualizar: (body) => http_client("/api/ctl_categoria_plaza/" + body.id,  body, "patch"),
  desactivar: (body) => http_client("/api/ctl_categoria_plaza/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/ctl_categoria_plaza", body, "post"),
};

const EstadoPlazas = {
  listar: ({params, data}) => http_client("/api/ctl_estado_plaza/search?" + getParams(params), data, "post"),
  listaOrdenada: (body) => http_client("/api/ctl_estado_plaza/search", body, "post"),
  actualizar: (body) => http_client("/api/ctl_estado_plaza/" + body.id,  body, "patch"),
  desactivar: (body) => http_client("/api/ctl_estado_plaza/"+ body.id, {}, "delete"),
  crear: (body) => http_client("/api/ctl_estado_plaza", body, "post"),
};


const PlazaNominal = {
  // listar: ({params, data}) => http_client("/api/mnt_detalle_plaza/search?" + getParams(params), data, "post"),
  listar: ({params, data}) => http_client("/api/mnt_detalle_plaza_nominal?" + getParams(params), data, "get"),
  actualizar: (body) => http_client("/api/mnt_detalle_plaza_nominal/" + body.id, body, "put"),
  desactivar: (body) => http_client("/api/mnt_detalle_plaza_nominal/" + body.id, {}, "delete"),
  // crear: (body) => http_client("/api/mnt_detalle_plaza/", body, "post"),
  crear: (body) => http_client("/api/mnt_detalle_plaza_nominal", body, "post"),
};

const remuneraciones = {
    listar: ({params, data}) => http_client('/api/mnt_remuneracion/search?' + getParams(params), data, 'post'),
    crear: (body) => http_client('/api/mnt_remuneracion', body, 'post'),
    actualizar: (body) => http_client('/api/mnt_remuneracion/' + body.id, body, 'patch'),
    desactivar: (body) => http_client('/api/mnt_remuneracion/' + body.id, {}, 'delete')

};

const AgrupadorPlaza = {
    listar: () => http_client(`/api/v1/plazas/listado-agrupadores`, {}, "get"),    
    // listar: ({ params, data }) => http_client(`/api/v1/plazas/listado-agrupadores/search?` + getParams(params), data, "post"),
  };

  const ListadoAgrupadores = {
    listarSinPaginar: () => http_client(`/api/v1/plazas/lista-agrupadores`, {}, "get"),
  };

  export default{
    SistemaPago,
    PresupuestoFinancia,
    TipoFinanciamiento,
    Formalizacion,
    CategoriaPlazas,
    EstadoPlazas,
    PlazaNominal,
    remuneraciones,
    AgrupadorPlaza,
    UnidadPresupuestaria,
    ListadoAgrupadores
  };